import React, { useRef, useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/pages/Support.scss"

import SubmitIcon from "../assets/images/icons/arrow-right.svg"
import IconScroll from "../assets/images/icons/scroll-top-icon.svg"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"

export default function Support({ location: { pathname } }) {
  const intl = useIntl()
  const [tab, setTab] = useState(0)
  const contentRef = useRef(null)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const tab0 = tab === 0
  const tab1 = tab === 1
  const tab2 = tab === 2
  const tab3 = tab === 3

  const handleTab = tabSelected => () => {
    setTab(tabSelected)
    contentRef.current.firstElementChild.scrollTo(0, 0)
    contentRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout pathname={pathname}>
      <Seo title="Soporte" />
      <Hero title={intl.formatMessage({ id: "support_title" })} />
      <section className="support">
        <form className="support__form">
          <h2 className="support__form-title">
            {intl.formatMessage({ id: "support_form_title" })}
          </h2>
          <div className="support__form-grid">
            <label htmlFor="name" className="support__form-label">
              <p className="support__form-label-text">
                {intl.formatMessage({ id: "name" })}:
              </p>
              <input
                id="name"
                name="name"
                type="text"
                className="support__form-input"
                required
              />
            </label>
            <label htmlFor="lastName" className="support__form-label">
              <p className="support__form-label-text">
                {intl.formatMessage({ id: "lastname" })}:
              </p>
              <input
                id="lastName"
                name="lastName"
                type="text"
                className="support__form-input"
                required
              />
            </label>
            <label htmlFor="country" className="support__form-label">
              <p className="support__form-label-text">
                {intl.formatMessage({ id: "country" })}:
              </p>
              <input
                id="country"
                name="country"
                type="text"
                className="support__form-input"
                required
              />
            </label>
            <label htmlFor="phone" className="support__form-label">
              <p className="support__form-label-text">
                {intl.formatMessage({ id: "phone" })}:
              </p>
              <input
                id="phone"
                name="phone"
                type="tel"
                pattern="^[0-9]{10,16}$"
                className="support__form-input"
                required
              />
              <p className="support__form-label-warning"></p>
            </label>
            <label htmlFor="email" className="support__form-label">
              <p className="support__form-label-text">
                {intl.formatMessage({ id: "email" })}:
              </p>
              <input
                id="email"
                name="email"
                type="email"
                className="support__form-input"
                required
              />
            </label>
            <label htmlFor="message" className="support__form-label">
              <p className="support__form-label-text">
                {intl.formatMessage({ id: "subject" })}:
              </p>
              <input
                id="message"
                name="message"
                type="text"
                className="support__form-input"
                required
              />
            </label>
          </div>
          <button type="submit" className="support__form-submit">
            {intl.formatMessage({ id: "send" })}:
            <img
              src={SubmitIcon}
              alt=""
              className="support__form-submit-icon"
            />
          </button>
        </form>
        <h1 className="support__faq">
          {intl.formatMessage({ id: "support_faq" })}
        </h1>
        <div className="support__container">
          <div className="support__navigation">
            <ul className="support__menu">
              <li data-active={tab0} className="support__menu-item">
                <button
                  onClick={handleTab(0)}
                  type="button"
                  className="support__menu-item-text"
                >
                  {intl.formatMessage({ id: "support_verification_title" })}
                </button>
              </li>
              <li data-active={tab1} className="support__menu-item">
                <button
                  onClick={handleTab(1)}
                  type="button"
                  className="support__menu-item-text"
                >
                  {intl.formatMessage({ id: "support_safety_title" })}
                </button>
              </li>
              <li data-active={tab2} className="support__menu-item">
                <button
                  onClick={handleTab(2)}
                  type="button"
                  className="support__menu-item-text"
                >
                  {intl.formatMessage({ id: "support_platform_title" })}
                </button>
              </li>
              <li data-active={tab3} className="support__menu-item">
                <button
                  onClick={handleTab(3)}
                  type="button"
                  className="support__menu-item-text"
                >
                  {intl.formatMessage({ id: "support_account_title" })}
                </button>
              </li>
            </ul>
          </div>
          <div ref={contentRef} className="support__content">
            <div className="support__content-scroll">
              {tab0 && (
                <>
                  <h1 className="support__content-title">
                    {intl.formatMessage({ id: "support_verification_title" })}
                  </h1>
                  <p className="support__content-text">
                    {intl.formatMessage({ id: "support_verification_text" })}
                  </p>
                </>
              )}
              {tab1 && (
                <>
                  <h1 className="support__content-title">
                    {intl.formatMessage({ id: "support_safety_title" })}
                  </h1>
                  <p className="support__content-text">
                    {intl.formatMessage({ id: "support_safety_text" })}
                  </p>
                </>
              )}
              {tab2 && (
                <>
                  <h1 className="support__content-title">
                    {intl.formatMessage({ id: "support_platform_title" })}
                  </h1>
                  <p className="support__content-text">
                    {intl.formatMessage({ id: "support_platform_text" })}
                  </p>
                </>
              )}
              {tab3 && (
                <>
                  <h1 className="support__content-title">
                    {intl.formatMessage({ id: "support_account_title" })}
                  </h1>
                  <p className="support__content-text">
                    {intl.formatMessage({ id: "support_account_text" })}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <button id="scroll-btn" className="up__button" title="Scroll to top">
        <img src={IconScroll} alt="Up" className="up__image" />
      </button>
    </Layout>
  )
}
